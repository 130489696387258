import React from 'react';
import { Box } from '@storyofams/react-ui';
import { WithStoryProps } from '@storyofams/storyblok-toolkit';
import { defineMessages, useIntl } from 'react-intl';
import SbEditable from 'storyblok-react';
import styled from 'styled-components';

import { Button, Seo, Text, Heading } from '~components';
import {
  Hero,
  InstagramOverview,
  Gallery,
  CallToAction,
  CollectionSection,
  CocktailSection,
} from '~components/sections';

import { InstagramItem } from '~lib/storyblok/sdk';
import { CollectionItems } from '~lib/storyblok/sdk';

interface HomeProps extends WithStoryProps {
  instagramSection?: InstagramItem;
  collections?: CollectionItems;
}

const GalleryContainer = styled(Box)`
  background: url('/images/gallery-background.svg');
  background-size: cover;
  background-position: 'center';
  color: white;
`;

const messages = defineMessages({
  viewAll: 'View all cocktails',
  preText: 'The world’s first cocktail brand',
});

export const Home = ({ story, collections }: HomeProps) => {
  const intl = useIntl();
  const { content } = story;

  return (
    <>
      <Seo story={story} />
      <SbEditable content={story?.content}>
        <Hero
          media={content?.header_visual?.[0]}
          imgSrc={content?.header_visual?.[0]?.image}
          textAlign="center"
          preText={intl.formatMessage(messages.preText)}
          pt={0}
        >
          {!!content?.header_title && (
            <Heading
              color="white"
              maxWidth="720px"
              variant="h1"
              as="h1"
              mb={0.5}
            >
              {content?.header_title}
            </Heading>
          )}
          {!!content?.header_description && (
            <Text
              color="white"
              fontSize={[2, 2.25]}
              maxWidth="480px"
              mb={[3, 5]}
            >
              {content?.header_description}
            </Text>
          )}
          {content?.header_button_text && (
            <Button
              variant="inverted"
              to={
                content?.header_button_link?.story?.fullSlug
                  ? `/${content?.header_button_link?.story?.fullSlug}`
                  : '/shop'
              }
            >
              {content?.header_button_text}
            </Button>
          )}
        </Hero>
      </SbEditable>

      {story?.content?.cocktails_list.length > 0 && (
        <SbEditable content={story?.content}>
          <CocktailSection
            css={{ button: { display: 'none' } }}
            divider={false}
            button={intl.formatMessage(messages.viewAll)}
            content={{
              title: story?.content?.cocktails_title,
              cocktails: story.content.cocktails_list,
            }}
          />
        </SbEditable>
      )}
      <SbEditable content={story?.content}>
        <GalleryContainer
          py={[9, 10]}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box mb={8}>
            <Heading variant="h1" as="h2" color="white" fontSize={[4, 6]}>
              {content?.gallery_title}
            </Heading>

            {content?.gallery_description && (
              <Text mt={2} color="white">
                {content?.gallery_description}
              </Text>
            )}
          </Box>

          <Gallery
            images={content?.gallery_images?.[0]?.images}
            alignment={content?.gallery_images?.[0]?.layout}
            asCard
          />

          {content?.gallery_link_label && (
            <Button
              variant="secondary"
              to={`/${content?.gallery_link_url?.story?.fullSlug}`}
              mt={8}
              display="inline-flex"
            >
              {content?.gallery_link_label}
            </Button>
          )}
        </GalleryContainer>
      </SbEditable>
      <SbEditable content={story?.content}>
        <CollectionSection
          title={content?.collection_title}
          items={collections?.items}
        />
      </SbEditable>

      {!!story?.content?.callToAction?.content && (
        <SbEditable content={story?.content?.callToAction?.content}>
          <CallToAction {...story?.content?.callToAction?.content} />
        </SbEditable>
      )}

      {!!story?.content?.instagram?.content && (
        <SbEditable content={story?.content?.instagram?.content}>
          <InstagramOverview {...story?.content?.instagram?.content} />
        </SbEditable>
      )}
    </>
  );
};

export default Home;
