import React, { useEffect } from 'react';
import { withStory, WithStoryProps } from '@storyofams/storyblok-toolkit';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';

import { useShopify } from '~context';
import {
  enhancedStaticProps,
  getTranslationForLocale,
  resolveCallToAction,
  withLayout,
} from '~lib';

import { Home as HomeComponent } from '~components/home';
import { InstagramItem } from '~lib/storyblok/sdk';
import { CollectionItems } from '~lib/storyblok/sdk';

interface HomePageProps extends WithStoryProps {
  instagramSection: InstagramItem;
  collections: CollectionItems;
}

const Home: NextPage<HomePageProps> = ({ story, collections }) => {
  const { replace } = useRouter();
  const { customer } = useShopify();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b') {
      if (customer?.id) {
        replace('/shop');
      } else {
        replace({
          pathname: `/login`,
          query: {
            to: '/shop',
            as: '/shop',
          },
        });
      }
    }
  }, []);

  return <HomeComponent story={story} collections={collections} />;
};

export default withLayout(withStory(Home));

export const getStaticProps: GetStaticProps<HomePageProps> =
  enhancedStaticProps(async ({ defaultLocale, locale, sdk }) => {
    let story = null;
    let notFound = false;
    let instagramSection = null;
    let collections = null;
    const { lang, needsTranslation } = getTranslationForLocale(
      locale,
      defaultLocale,
    );

    try {
      story = (
        await sdk.home({
          slug: `${needsTranslation ? `${lang}/` : ''}home`,
          lang: needsTranslation ? lang : undefined,
        })
      ).HomeItem;

      await resolveCallToAction(story, sdk, lang, defaultLocale);
    } catch (e) {
      notFound = true;
    }

    try {
      collections = (
        await sdk.collectionItems({
          filter: { isHidden: { is: 'false' } },
          prefix: `${needsTranslation ? `${lang}/*` : ''}`,
        })
      ).CollectionItems;
    } catch (e) {}

    return {
      props: {
        story,
        collections,
        instagramSection,
      },
      notFound: notFound || !story,
      revalidate: 60,
    };
  });
